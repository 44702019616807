import { CrudeTab } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const crudeTabConfig = (): CrudeTab[] => {
  return [
    {
      entity: 'Log',
    },
    {
      entity: 'Installation',
      columns: [
        {
          name: 'wattandoBox',
          hideInTableView: false,
        },
        {
          name: 'state',
          hideInTableView: false,
        },
        {
          name: 'installationType',
          hideInTableView: false,
        },
        {
          name: 'netCode',
          hideInTableView: false,
        },
        {
          name: 'address',
          hideInTableView: false,
        },
        {
          name: 'connectionType',
          hideInTableView: false,
        },
        {
          name: 'loraFrequency',
          hideInTableView: false,
        },
        {
          name: 'electrician',
          hideInTableView: false,
        },
        {
          name: 'notes',
          hideInTableView: false,
        },
      ],
    },
    {
      entity: 'WattandoBox',
      columns: [
        {
          name: 'modelId',
          hideInTableView: false,
        },
        {
          name: 'serialNumber',
          hideInTableView: false,
        },
        {
          name: 'controllerboardSerialNumber',
          hideInTableView: false,
        },
        {
          name: 'powerboardSerialNumber',
          hideInTableView: false,
        },
        {
          name: 'ledBoardSerialNumber',
          hideInTableView: false,
        },
        {
          name: 'wifiName',
          hideInTableView: false,
        },
        {
          name: 'wifiPassword',
          hideInTableView: false,
        },
        {
          name: 'hardwareRevision',
          hideInTableView: false,
        },
        {
          name: 'lastWebsocketConnect',
          hideInTableView: false,
        },
        {
          name: 'powerboardFirmware',
          hideInTableView: false,
        },
        {
          name: 'powerboardDeviceFleet',
          hideInTableView: false,
        },
        {
          name: 'hawkbitSecurityToken',
          hideInTableView: false,
        },
        {
          name: 'disabledAt',
          hideInTableView: false,
        },
      ],
    },
    {
      entity: 'Sensor',
      tabs: [
        {
          sourceEntity: 'Installation',
          sourceColumn: 'sensorMain',
          hideEntity: false,
          label: {
            'de-DE': 'Installationen Hauptverteilung',
          },
        },
        {
          sourceEntity: 'Installation',
          sourceColumn: 'sensorSub',
          hideEntity: false,
          label: {
            'de-DE': 'Installationen Unterverteilung',
          },
        },
      ],
    },
    {
      entity: 'Bridge',
    },
    {
      entity: 'DeviceFleet',
    },
    {
      entity: 'Firmware',
    },
    {
      entity: 'Inverter',
    },
    {
      entity: 'User',
      columns: [
        {
          name: 'firstname',
          hideInTableView: false,
        },
        {
          name: 'surname',
          hideInTableView: false,
        },
        {
          name: 'email',
          hideInTableView: false,
        },
        {
          name: 'role',
          hideInTableView: false,
        },
        {
          name: 'companyName',
          hideInTableView: false,
        },
        {
          name: 'taxId',
          hideInTableView: false,
        },
        {
          name: 'legalForm',
          hideInTableView: false,
        },
        {
          name: 'allowedToInstall',
          hideInTableView: false,
        },
        {
          name: 'newsletter',
          hideInTableView: false,
        },
        {
          name: 'active',
          hideInTableView: false,
        },
        {
          name: 'lastLogin',
          hideInTableView: true,
        },
        {
          name: 'activatedAt',
          hideInTableView: true,
        },
        {
          name: 'created',
          hideInTableView: true,
        },
      ],
      tabs: [
        {
          sourceEntity: 'Installation',
          sourceColumn: 'customer',
          hideEntity: false,
          label: {
            'de-DE': 'Eigene Installationen',
          },
        },
        {
          sourceEntity: 'Installation',
          sourceColumn: 'electrician',
          hideEntity: false,
          label: {
            'de-DE': 'Installationen als Installateur',
          },
        },
      ],
    },
    {
      entity: 'Company',
    },
    {
      entity: 'Location',
    },
    {
      entity: 'Country',
    },
    {
      entity: 'NetCode',
    },
  ]
}
export default crudeTabConfig
