/**
 * TooltipComponent
 *
 */

import React, { useState, useEffect } from 'react'

import { Theme } from '@mui/material'

import HelpIcon from '@mui/icons-material/Help'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'

import TooltipComponent from './TooltipComponent'
import { makeStyles } from '@mui/styles'

import { useSelector } from 'react-redux'
import { AppLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import { getAppLayout } from '../../../store/settings/settings.selectors'

interface Props {
  description?: string
  label: string
  placement?: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltipIcon: {
    cursor: 'pointer',
    margin: '0 0 0 5px',
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}))

const LabelWithTooltipComponent: React.FC<Props> = (props) => {
  const appLayout: AppLayout | undefined = useSelector(getAppLayout)
  const classes = useStyles()

  const getIcon = (param: null | 'help' | 'info' | 'lightbulb' | 'helpOutline' | 'helpCenter') => {
    switch (param) {
      case 'help':
        return <HelpIcon className={classes.tooltipIcon} />
      case 'lightbulb':
        return <LightbulbIcon className={classes.tooltipIcon} />
      case 'helpOutline':
        return <HelpOutlineIcon className={classes.tooltipIcon} />
      case 'helpCenter':
        return <HelpCenterIcon className={classes.tooltipIcon} />
      case 'info':
        return <HelpCenterIcon className={classes.tooltipIcon} />
      default:
        return null
    }
  }

  const [tooltipIcon, setTooltipIcon] = useState<JSX.Element | null>(null)

  useEffect(() => {
    appLayout?.textfield?.tooltipIcon && setTooltipIcon(getIcon(appLayout?.textfield?.tooltipIcon))
  }, [appLayout])

  return (
    <div className={classes.tooltipContainer}>
      {props.label}
      {props.description && tooltipIcon &&
        <TooltipComponent
          title={props.description}
          element={tooltipIcon}
        />}
    </div>
  )
}
export default LabelWithTooltipComponent
