import React, { useEffect, useRef, useState } from 'react'
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import Env from '../../shared/config/envConfig'
import { makeStyles, styled } from '@mui/styles'
import CardComponent from './CardComponent'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUser } from '../../store/settings/settings.selectors'
import formatDate from '@3m5/crude-frontend/dist/shared/util/formatDate'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
}))

const Dashboard: React.FC = () => {
  const classes = useStyles()

  const user = useSelector(getUser)
  const theme = useTheme()
  const { t } = useTranslation('dashboard')

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    [theme.breakpoints.down('md')]: {
      '& td, th': {
        border: 0,
      },
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))

  const [mobileLayout, setMobileLayout] = useState(false)

  const [data, setData] = useState<any>()

  const rootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const restUrl = `${Env.basePath}/api/dashboard`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        setData(json)
      })
      .catch(err => console.log(err))
  }, [],
  )

  useEffect(() => {
    if (rootRef.current) {
      const element: HTMLElement = rootRef.current

      setMobileLayout(element.offsetWidth < 600)

      const observer = new ResizeObserver(() => {
        setMobileLayout(element.offsetWidth < 600)
      })
      observer.observe(element)

      return () => {
        observer.disconnect(element)
      }
    }
  }, [rootRef, setMobileLayout])

  return (
    <Grid ref={rootRef} container className={classes.root}>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: theme.palette.primary.main, borderRadius: '10px', px: mobileLayout ? 2 : 4, py: mobileLayout ? 2 : 5 }}>
            <Typography variant='h4' sx={{ color: theme.palette.primary.contrastText }}>{`Hallo ${user?.firstName} ${user?.lastName}`}</Typography>
            <Typography variant='h6' sx={{ color: theme.palette.primary.contrastText }}>Willkommen zurück im Admin-Bereich.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} container spacing={3} direction={mobileLayout ? 'column-reverse' : 'row'}>
          <Grid item xs={mobileLayout ? undefined : 6}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 500}>
              <Stack spacing={1}>
                <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>Neueste Installationen</Typography>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600 }}>Wattsterbox</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Typ</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>erstellt am</TableCell>
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {data && data.newestInstallations?.map((installation: any, index: number) => (
                        <StyledTableRow
                          key={index}
                        >
                          {mobileLayout ? (
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>Wattsterbox</Grid>
                                <Grid item xs={6}><Typography>{installation.wattandoBox.serialNumber}</Typography></Grid>
                                <Grid item xs={6}>Status</Grid>
                                <Grid item xs={6}><Typography>{t(`state:${installation.state || '-'}`)}</Typography></Grid>
                                <Grid item xs={6}>Typ</Grid>
                                <Grid item xs={6}><Typography>{t(`installationType:${installation.installationType || '-'}`)}</Typography></Grid>
                                <Grid item xs={6}>erstellt am</Grid>
                                <Grid item xs={6}><Typography>{formatDate(new Date(installation.createTime).getTime() / 1000)}</Typography></Grid>
                              </Grid>
                            </TableCell>)
                            : (
                              <>
                                <TableCell>{installation.wattandoBox.serialNumber}</TableCell>
                                <TableCell>{t(`state:${installation.state || '-'}`)}</TableCell>
                                <TableCell>{t(`installationType:${installation.installationType || '-'}`)}</TableCell>
                                <TableCell>{formatDate(new Date(installation.createTime).getTime() / 1000)}</TableCell>
                              </>)}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
          <Grid item xs={mobileLayout ? undefined : 6}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 500}>
              <Stack spacing={1}>
                <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>Neueste Protokolle</Typography>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600 }}>Installation</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Protokoll-Typ</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Nachricht</TableCell>
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {data && data.newestLogs?.map((log: any, index: number) => (
                        <StyledTableRow
                          key={index}
                        >
                          {mobileLayout ? (
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>Installation</Grid>
                                <Grid item xs={6}><Typography>{log.installation.wattandoBox.serialNumber}</Typography></Grid>
                                <Grid item xs={6}>Protokoll-Typ</Grid>
                                <Grid item xs={6}><Typography>{t(`logType:${log.type}`)}</Typography></Grid>
                                <Grid item xs={6}>Nachricht</Grid>
                                <Grid item xs={6}><Typography>{log.msg}</Typography></Grid>
                              </Grid>
                            </TableCell>)
                            : (
                              <>
                                <TableCell>{log.installation.wattandoBox.serialNumber}</TableCell>
                                <TableCell>{t(`logType:${log.type}`)}</TableCell>
                                <TableCell>{log.msg}</TableCell>
                              </>)}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Dashboard
