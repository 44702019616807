import { EditLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = (): EditLayout => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Installation',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'wattandoBox',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'state',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'installationType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'netCode',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'address',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'connectionType',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'loraFrequency',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'electrician',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'notes',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'customer',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'emailCustomer',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'phoneCustomer',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'customerHasNotice',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'createTime',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'updateTime',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'startedAt',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'finishedAt',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'customerLinkedAt',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'sensorMain',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'mainDistributionAge',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'mainDistributionFuse',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'sensorSub',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'subDistributionAge',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'subDistributionFuse',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'operationMode',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 13,
                to: 13,
              },
            },
          },
          {
            name: 'regulationType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 14,
                to: 14,
              },
            },
          },
          {
            name: 'gridFeedType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
          {
            name: 'zeroInjectionType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 16,
                to: 16,
              },
            },
          },
          {
            name: 'gridFeedPhase',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 17,
                to: 17,
              },
            },
          },
          {
            name: 'storageRegulationType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 18,
                to: 18,
              },
            },
          },
          {
            name: 'inverterModel',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 13,
                to: 13,
              },
            },
          },
          {
            name: 'inverterSerial',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 14,
                to: 14,
              },
            },
          },
          {
            name: 'inverterFirmwareVersion',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
          {
            name: 'batteryModel',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 19,
                to: 19,
              },
            },
          },
          {
            name: 'batterySerial',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 20,
                to: 20,
              },
            },
          },
          {
            name: 'batteryCharge',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 21,
                to: 21,
              },
            },
          },
          {
            name: 'batteryFirmwareVersion',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 22,
                to: 22,
              },
            },
          },
          {
            name: 'gridMeterType',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 19,
                to: 19,
              },
            },
          },
          {
            name: 'gridMeterSerial',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 20,
                to: 20,
              },
            },
          },
          {
            name: 'pvModuleCount',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 23,
                to: 23,
              },
            },
          },
          {
            name: 'pvModulePower',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 24,
                to: 24,
              },
            },
          },
          {
            name: 'pvModuleStrings',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 23,
                to: 23,
              },
            },
          },
          {
            name: 'pvModuleProducer',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 24,
                to: 24,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 4,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 5,
                    to: 9,
                  },
                },
                name: 'customer',
                title: {
                  'de-DE': 'Kunde',
                  'en-US': 'Customer',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 5,
                    to: 9,
                  },
                },
                name: 'time',
                title: {
                  'de-DE': 'Zeit',
                  'en-US': 'Time',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 10,
                    to: 12,
                  },
                },
                name: 'mainDistribution',
                title: {
                  'de-DE': 'Hauptverteilung',
                  'en-US': 'Main distribution',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 10,
                    to: 12,
                  },
                },
                name: 'subDistribution',
                title: {
                  'de-DE': 'Unterverteilung',
                  'en-US': 'Sub distribution',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 13,
                    to: 18,
                  },
                },
                name: 'regulation',
                title: {
                  'de-DE': 'Regelung',
                  'en-US': 'Regulation',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 13,
                    to: 18,
                  },
                },
                name: 'inverter',
                title: {
                  'de-DE': 'Wechselrichter',
                  'en-US': 'Inverter',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 19,
                    to: 22,
                  },
                },
                name: 'battery',
                title: {
                  'de-DE': 'Batterie',
                  'en-US': 'Battery',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 19,
                    to: 22,
                  },
                },
                name: 'counter',
                title: {
                  'de-DE': 'Zähler',
                  'en-US': 'Counter',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 23,
                    to: 24,
                  },
                },
                name: 'pvModules',
                title: {
                  'de-DE': 'PV Module',
                  'en-US': 'PV modules',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'User',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'email',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'role',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'active',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'newsletter',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'allowedToInstall',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'firstname',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'surname',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'companyName',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'taxId',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'legalForm',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'created',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'activatedAt',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'lastLogin',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 5,
                  },
                },
                name: 'account',
                title: {
                  'de-DE': 'Zugang',
                  'en-US': 'Account',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 5,
                  },
                },
                name: 'time',
                title: {
                  'de-DE': 'Zeit',
                  'en-US': 'Time',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 6,
                    to: 8,
                  },
                },
                name: 'person',
                title: {
                  'de-DE': 'Person',
                  'en-US': 'Person',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 6,
                    to: 8,
                  },
                },
                name: 'company',
                title: {
                  'de-DE': 'Unternehmen',
                  'en-US': 'Company',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Firmware',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'version',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'type',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'note',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'fileIds',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 3,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'files',
                title: {
                  'de-DE': 'Dateien',
                  'en-US': 'Files',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'WattandoBox',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'modelId',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'hawkbitSecurityToken',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'disabledAt',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'serialNumber',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'controllerboardSerialNumber',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'powerboardSerialNumber',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'ledBoardSerialNumber',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'lastWebsocketConnect',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'wifiName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'wifiPassword',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'hardwareRevision',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'powerboardFirmware',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'powerboardDeviceFleet',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'wattster',
                title: {
                  'de-DE': 'Wattster',
                  'en-US': 'Wattster',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'serialNumbers',
                title: {
                  'de-DE': 'Seriennummern',
                  'en-US': 'Serial numbers',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 4,
                    to: 7,
                  },
                },
                name: 'controllerboard',
                title: {
                  'de-DE': 'Controllerboard',
                  'en-US': 'Controllerboard',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 4,
                    to: 7,
                  },
                },
                name: 'powerboard',
                title: {
                  'de-DE': 'Powerboard',
                  'en-US': 'Powerboard',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
