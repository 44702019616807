import React from 'react'
import { Button } from '@mui/material'
import { EntityData } from '@3m5/crude-frontend/dist/shared/types/dataTypes'
import Env from '../../shared/config/envConfig'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface InjectedTableActionComponentProps {
  entity: string
  entityId: string
  treeViewLevel: number
  entityData: EntityData
  sharedData: Record<string, any>
}
const TableActionButtons: React.FC<InjectedTableActionComponentProps> = (props) => {
  const basePath = Env.basePath
  const history = useHistory()
  const { t } = useTranslation()

  const copyLicense = () => {
    const url = `${basePath}/api/parent/license/${props.entityId}/duplicate`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
      })
      .then(response => response.json())
      .then(json => {
        history.push(`${json}`)
      })
  }

  return (
    <>
      {props.entity === 'License' && false &&
        <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={() => copyLicense()}>{t('common:copyLicense')}</Button>}
    </>
  )
}
export default TableActionButtons
