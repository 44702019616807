import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Box, Tabs, Tab } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import SwitchComponent from '../../shared/components/FormComponents/SwitchComponent'
import * as settingsActions from '../../store/settings/settings.actions'
import { getPrefersDarkMode } from '../../store/settings/settings.selectors'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const Settings: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const darkMode = useSelector(getPrefersDarkMode)

  function TabPanel (props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps (index: number) {
    return {
      id: `tab-${index}`,
    }
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={t('common:settings')} {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SwitchComponent
            label='Dark Mode'
            checked={darkMode}
            onChange={(checked: boolean) => {
              dispatch(settingsActions.setPrefersDarkMode(checked))
            }}
            name='checkedA'
            color='primary'
          />
        </TabPanel>
      </Box>
    </>
  )
}
export default Settings
